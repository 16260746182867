import React from 'react';

import './headerNavBtn.css'
import FloatBtn from "../floatBtn";

const HeaderNavBtn = () => {

    return (
        <div className="headerNavBtn">
            <div className="NavBtn">
                <FloatBtn />
            </div>
            <div className="NavTitle">
                <a href="/">Welcome to my resume!</a>
            </div>
        </div>
    )

}

export default HeaderNavBtn;