import React, { useState } from 'react';
import './floatBtn.css'

const FloatBtn = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="hamburger-menu">
            <div className="hamburger-icon" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            {isOpen && (
                <div className="menu-links">
                    <a href="#about">About</a>
                    <a href="#skills">SKILLS</a>
                    <a href="cv">CV</a>
                </div>
            )}
        </div>
    );
}

export default FloatBtn